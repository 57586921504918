import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'gatsby'
import Img1 from '../images/hero-internal-door.png'
import video1 from '../images/internal-door-constructor.mp4'
import ImgQuote from '../images/quote.png'

export default function InternalDoors(props) {
	return <>
		<Helmet>
			<title>App for Internal Doors | Kelgrand</title>
		</Helmet>

		<div style={{
			backgroundColor: '#E6F0E1',
			paddingBottom: '3rem',
		}}>

			<Header path={props.path} />

			<main className='container mt-5'>
				<div className='row'>
					<div className='col-sm-4'>
						<h1 className='display-3 green-heading'>
							App for Internal Doors
						</h1>
						<h2 className='green-heading mt-5'>
							Quotes and Manufacturing
						</h2>
						<p>
							Purpose build solution for resellers and manufacturers of internal doors. You can create quotes, purchase orders, bill of materials, work orders.
						</p>
					</div>
					<div className='col-sm-7 offset-sm-1'>
						<img src={Img1} style={{width:'100%'}} />
					</div>
				</div>
			</main>
		</div>

		<div className='container'>
			<div className='row mt-5 mb-5'>
				<div className='col-sm-4'>
					<h2 className='green-heading mt-5'>
						Beautiful images
					</h2>
					<h2 className='green-heading mt-5'>
						Accurate pricing
					</h2>
					<p>
						No More Guesswork.
					</p>
				</div>
				<div className='col-sm-6 offset-sm-2'>
					<video src={video1} autoPlay={true} loop={true} muted={true} playsInline={true} controlsList="nodownload" style={{width: '100%'}} />
				</div>
			</div>

			<hr />

			<h2 className='green-heading text-center mt-5 mb-5'>
				Your new quotes could look like this
			</h2>

			<div className='row mt-5 mb-5'>
				<div className='col-sm-7'>
					<img src={ImgQuote} style={{width:'100%', boxShadow: '0 0 30px #ccc'}} />
				</div>
				
				<div className='col-sm-4 offset-sm-1'>
					<h3 className='green-heading'>
						Your logo
					</h3>
					<p>
						Your brand and contact details
					</p>

					<h3 className='green-heading'>
						Customer info
					</h3>
					<p>
						Add customer's phone and email 
					</p>

					<h3 className='green-heading'>
						Your Advantages
					</h3>
					<p>
						Add beautiful images that showcase your advantages and materials used. You can have multiple templates. 
					</p>

					<h3 className='green-heading'>
						Image
					</h3>
					<p>
						Images are dynamically generated based on your configuration. You can change color, dimensions, design, opening, handle, glass.
					</p>

					<h3 className='green-heading'>
						Description + Price
					</h3>
					<p>
						For every door there is description of hardware used. Description can be short or extensive (ready for production). You are in control.
					</p>
					<p>
						Prices are calculated based on dimensions and other parameters.
					</p>
				</div>
			</div>
		</div>

		<Footer />
	</>
}
